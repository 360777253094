import React from "react"
import { css } from "@emotion/core"

export default function Source({ children, color }) {
  return (
    <a
      href={"/resources/"}
      target={"_blank"}
      css={p => css`
        color: ${color ? p.colors[color] : p.colors.seafoam};
        text-decoration: none;
        cursor: pointer;
        margin-left: 3px;
        :hover {
          text-decoration: underline;
        }
      `}
    >
      <sup
        css={css`
          top: -0.75em;
          font-size: 60%;
          margin-left: -3px;
        `}
      >
        &nbsp;[{children}]
      </sup>
    </a>
  )
}
