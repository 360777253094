import React, { useState } from "react"
import { Box } from "reflexbox"
import Img from "gatsby-image"

import { Heading, Text } from "~/components/common/Typography"
import CustomPlayer from "~/components/common/CustomPlayer"

const HeroVideo = ({
  headingSmall,
  headingLarge,
  headingLargeProps,
  text,
  textMt,
  underText,
  source,
  sourceProps,
  videoPoster,
  videoSrc,
  videoLabel,
  image,
  bg = "white",
  ...props
}) => {
  const [controls, setControls] = useState(false)
  return (
    <Box
      as="section"
      flexDirection="column"
      justifyContent="start"
      textAlign="center"
      pt="6"
      px={["8px", "32px", "120px"]}
      pb={["32px", "64px"]}
      bg="seafoam"
      {...props}
    >
      <Box display="inline-block" px={"6"}>
        {headingSmall && (
          <Box>
            <Box display="inline-block" maxWidth="desktop" mx={"auto"}>
              <Heading
                textAlign={"center"}
                fontSize={["14px", "20px"]}
                lineHeight={["26px", "36px"]}
                background="yellow"
                fontFamily={"AdineuePROBlackWeb"}
              >
                {headingSmall}
              </Heading>
            </Box>
          </Box>
        )}
        {headingLarge && (
          <Box>
            <Box
              display="inline-block"
              pt={headingSmall ? "5" : "0"}
              maxWidth="desktop"
              mx={"auto"}
            >
              <Heading
                textAlign={"center"}
                fontSize={["20px", "40px"]}
                lineHeight={["33px", "60px"]}
                background="yellow"
                fontFamily={"AdineuePROBlackWeb"}
                {...headingLargeProps}
              >
                {headingLarge}
              </Heading>
            </Box>
          </Box>
        )}
        {text && (
          <Box
            display="inline-block"
            maxWidth="desktop"
            mt={textMt ? textMt : "5"}
          >
            <Text
              fontSize={["14px", "18px", "24px"]}
              lineHeight={["21px", "27px", "36px"]}
              color={"black"}
              mb="5"
              mt={0}
            >
              {text}
            </Text>
          </Box>
        )}
        {source && (
          <Box display="inline-block" maxWidth="desktop" mt={"3"}>
            <Text
              fontSize={["16px", "18px"]}
              lineHeight={["24px", "36px"]}
              mb="5"
              mt={0}
              {...sourceProps}
            >
              {source}
            </Text>
          </Box>
        )}
      </Box>
      <Box
        maxWidth="desktop"
        mx="auto"
        bg={bg}
        px={["8px", "16px", "50px"]}
        pb={["8px", "16px", "50px"]}
        pt={text || source ? ["8px", "16px", "50px"] : ["30px", "38px", "50px"]}
        mt={text || source ? [0] : ["-25px"]}
        box
      >
        <Box>
          {image ? (
            <Img
              fluid={image.childImageSharp.fluid}
              alt={"Hero image"}
              loading={"eager"}
            />
          ) : (
            <CustomPlayer url={videoSrc} videoLabel={videoLabel}></CustomPlayer>
          )}
        </Box>
      </Box>
      {underText && (
        <Box display="inline-block" px={"6"}>
          {underText}
        </Box>
      )}
    </Box>
  )
}

export default HeroVideo
