import { css } from "@emotion/core"
import ReactGA from "react-ga"
import FilePlayer from "react-player/lib/players/FilePlayer"
import React, { useState } from "react"
import PlaySvg from "~/images/play-arrow.svg"
import { Box } from "reflexbox"
import { Text } from "~/components/common/Typography"
import { mq } from "~/components/common/theme"

const playMq = mq({
  width: ["60px", "90px"],
  height: ["60px", "90px"],
  minWidth: ["60px", "90px"],
  minHeight: ["60px", "90px"],
})

const CustomPlayer = ({ url, vertical = false, videoLabel = null }) => {
  const aspectRatio = vertical ? 1080 / 1920 : 1920 / 1080
  const [controls, setControls] = useState(false)
  const [playing, setPlaying] = useState(false)
  const logVideoPlay = () => {
    ReactGA.event({
      category: "video",
      action: "play",
      label: videoLabel,
    })
  }
  return (
    <div
      css={css`
        position: relative;
        background-color: transparent;
        padding-top: ${100 / aspectRatio}%;
        video {
          outline: none;
        }
      `}
    >
      <FilePlayer
        onClick={() => {
          setControls(true)
        }}
        width="100%"
        height="100%"
        url={url}
        playsinline
        playing={playing}
        controls={controls}
        config={{
          file: {
            attributes: {
              preload: "auto",
            },
          },
        }}
        css={css`
          position: absolute;
          top: 0;
          left: 0;
        `}
      ></FilePlayer>
      <div
        css={css`
          display: ${playing ? "none" : "block"};
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          color: white;
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.5);
        `}
        onClick={() => {
          logVideoPlay()
          setPlaying(true)
          setControls(true)
        }}
      >
        <Box
          css={css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          `}
        >
          <PlaySvg css={playMq} />
          <Text
            color={"white"}
            fontFamily={"AdineuePROBoldWeb"}
            fontSize={["16px", "24px"]}
          >
            PLAY VIDEO
          </Text>
        </Box>
      </div>
    </div>
  )
}

export default CustomPlayer
