import React from "react"

import Layout from "~/components/common/Layout"
import { getBackLink, getHeaderTitles, getNextLink, getNextTitle } from "~/services/pages"
import withProgress from "~/services/withProgress"
import HeroVideo from "~/components/page/HeroVideo"
import { graphql, useStaticQuery } from "gatsby"
import { Infobox } from "~/components/page/Infobox"
import Copyright from "~/components/common/Copyright"
import Source from "~/components/common/Source"

const Page = ({ myPage = 5, mySubpage = 0 }) => {
  const data = useStaticQuery(graphql`
      query {
          video: file(name: { eq: "ADIDAS_DigitalCurriculum_Video10_CTM_3bit" }) {
              name
              publicURL
          }
      }
  `)

  const { video } = data

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingLarge={
          <span>
            Female athletes fare better in nearly every aspect of adolescence
            compared to non-sports-playing peers.<Source>44</Source>
          </span>
        }
        videoSrc={video.publicURL}
        videoLabel={video.name}
        bg={"white"}
      />

      <Infobox uppercase>
        Girls who play sports optimize the function of their brain, body, and
        mind. But they don’t just benefit from improved fitness and physical
        well-being. They are also less likely to be at risk for unhealthy
        behaviors and disease throughout their lifespan.
        <br/>
        <br/>
        But a majority of girls still drop out of sports when they reach
        puberty. In order to change that, let’s learn how to keep girls in
        sports as their bodies undergo changes and they develop into women.
      </Infobox>

      <Copyright bg={"red"}/>
    </Layout>
  )
}

export default withProgress(Page, 5, 0)
