import React from "react"
import { Box } from "reflexbox"
import { css } from "@emotion/core"
import { Heading } from "~/components/common/Typography"

const Infobox = ({ children, background, maxWidth, uppercase }) => {
  return (
    <Box
      as="section"
      flexDirection="column"
      justifyContent="start"
      textAlign="center"
      pt="6"
      px={["16px", "32px"]}
      pb="6"
      bg={background ? background : "red"}
    >
      <Box
        textAlign="center"
        maxWidth={maxWidth ? maxWidth : "blockText"}
        mx="auto"
        mb="6"
        mt="4"
        fontSize={["18px", "24px"]}
        lineHeight={["27px", "36px"]}
        textAlign="center"
        fontFamily={"AdineuePROBoldWeb"}
        css={css`
          text-transform: ${uppercase ? "uppercase" : "inherit"};
        `}
      >
        {children}
      </Box>
    </Box>
  )
}

const InfoboxDetailed = ({
                           children,
                           headerText,
                           headerBackground,
                           contentMaxWidth,
                           headerMaxWidth,
                           headerProps,
                           ...props
                         }) => {
  return (
    <Box
      flexDirection="column"
      justifyContent="center"
      px={["16px", "32px"]}
      bg="white"
      pt={[6, 7]}
      pb={[6, 7]}
      textAlign="center"
      {...props}
    >
      {headerText && (
        <Box
          maxWidth={headerMaxWidth ? headerMaxWidth : "mediumContent"}
          mx="auto"
        >
          <Heading
            as={"h2"}
            fontSize={["24px", "32px", "48px"]}
            lineHeight={["33px", "42px", "60px"]}
            background={headerBackground ? headerBackground : "yellow"}
            fontFamily="AdineuePROBlackWeb"
            px={3}
            {...headerProps}
          >
            {headerText}
          </Heading>
        </Box>
      )}
      {children && (
        <Box
          fontSize={["16px", "18px"]}
          lineHeight={["24px", "36px"]}
          maxWidth={contentMaxWidth ? contentMaxWidth : "100%"}
          mx="auto"
        >
          {children}
        </Box>
      )}
    </Box>
  )
}

export { Infobox, InfoboxDetailed }
